@import './css/main';

:root {
  --ion-color-primary: #{$brand-warning};
  --ion-backdrop-color: rgba(255, 255, 255, 0.5);
  --ion-backdrop-opacity: 1;

  --app-find-shop-main-navbar-border-radius: 20px;
  --app-find-shop-box-shadow-sm: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --app-find-shop-box-shadow-lg: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
  --app-find-shop-dropdown-shadow: var(--app-find-shop-box-shadow-sm);
  --app-find-shop-dropdown-border-radius: 16px;
  --app-find-shop-card-border-radius: 16px;
  --app-find-shop-sidebar-width: #{lins(350px, 450px)};
  --app-find-shop-padding-2: #{lins(10px, 16px, 320px, 500px)};
  --app-find-shop-black-navbar-height: 40px;
  --app-find-shop-main-navbar-height: 86px;
  --app-find-shop-toolbar-height: 40px;
}

ion-modal.transparent-modal {
  pointer-events: none !important;
  --border-radius: var(--app-find-shop-card-border-radius);
}

ion-modal.transparent-modal::part(content) {
  overflow: visible; //in order to show best choice label
  box-shadow: var(--app-find-shop-box-shadow-lg);
}

ion-modal.transparent-modal::part(handle) {
  margin-top: 12px;
}

ion-backdrop {
  backdrop-filter: blur(4px);
}

ul.lug-dropdown-content-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 7;
  transform: translate3d(0, 0, 20px);

  &.sm {
    font-size: 12px;

    a {
      padding: 0.7em 1.2em;
    }
  }

  li {
    background-color: white;
    transition: background 400ms ease;

    &:hover {
      background-color: $gray-even-lighter;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-color-lighter;
    }
  }

  a {
    display: block;
    color: $lug24-text-color;
    padding: 1em 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;

    > span:nth-child(2) {
      margin-left: 0.5em;
      color: $lug24-text-color-lighter;
      font-size: 12px;
    }

    &.multi-line {
      display: flex;
      flex-direction: column;

      > span {
        margin: 0;
        white-space: wrap;
      }
    }
  }
}
